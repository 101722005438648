import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Search from "./common/Search";
import Sidebar from "./common/Sidebar";
import StickyMenu from "./common/StickyMenu";
import MobileMenu from "./common/MobileMenu";
import { Styles } from "./styles/header.js";
import { getDepartment } from "../redux/actions/department.action";
import { URL } from "../redux/actions";

const Header = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDepartment());
  }, []);
  const { departments } = useSelector((state) => state.departments);
  return (
    <Styles>
      {/* Topbar */}
      <section className="top-bar">
        <Container>
          <Row>
            <Col lg="6" md="5">
              <div className="bar-left">
                <ul className="list-unstyled list-inline">
                  <li className="list-inline-item">
                    <i className="las la-map-marker"></i>الزاوية - شارع صلاح
                    الدين
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg="6" md="7">
              <div className="bar-right d-flex justify-content-end">
                <ul className="list-unstyled list-inline bar-social">
                  <li className="list-inline-item">
                    <a href={process.env.PUBLIC_URL + "/"}>
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={process.env.PUBLIC_URL + "/"}>
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={process.env.PUBLIC_URL + "/"}>
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li className="list-inline-item"></li>
                </ul>

                <ul className="list-unstyled list-inline bar-login">
                  <li className="list-inline-item">
                    <a target="_blank" href={`${URL}`}>
                      <i className="las la-user"></i>تسجيل دخول
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={`${URL}signup`} target="_blank">
                      <i className="las la-user-edit"></i>سجل الان
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Logo Area */}
      <section className="logo-area">
        <Container>
          <Row>
            <Col md="3">
              <div className="logo">
                <Link to={process.env.PUBLIC_URL + "/"}>
                  <img src="assets/images/univ.png" alt="" width="200" />
                </Link>
              </div>
            </Col>
            <Col md="9">
              <div className="logo-contact-box d-flex justify-content-end">
                <div className="emcontact-box d-flex">
                  <div className="box-icon">
                    <i className="flaticon-phone-call"></i>
                  </div>
                  <div className="box-content">
                    <p>اتصل الان</p>
                    <span> 763-11-14 (023) </span>
                  </div>
                </div>
                <div className="emcontact-box d-flex">
                  <div className="box-icon">
                    <i className="flaticon-envelope"></i>
                  </div>
                  <div className="box-content">
                    <p>للتواصل </p>
                    <span> info@dentist.zu.edu.ly</span>
                  </div>
                </div>
                <div className="apply-btn">
                  <a href={`${URL}signup`} target="_blank">
                    <i className="las la-clipboard-list"></i>سجل الان
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Navbar */}
      <section className="main-menu">
        <Container>
          <Row>
            <Col md="12">
              <div className="main-menu-box">
                <div className="menu-box d-flex justify-content-between">
                  <ul className="nav menu-nav">
                    <li className="nav-item  active">
                      <Link className="nav-link" to="/">
                        الرئيسية
                      </Link>
                    </li>
                    <li className="nav-item  active">
                      <Link className="nav-link" to="/about">
                        عن الكلية
                      </Link>
                    </li>

                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to={process.env.PUBLIC_URL + "/"}
                        data-toggle="dropdown"
                      >
                        الاقسام <i className="las la-angle-down"></i>
                      </Link>
                      <ul className="dropdown list-unstyled ">
                        {departments &&
                          departments.map((department) => (
                            <li className="nav-item">
                              <Link className="nav-link " to={department.Url}>
                                {department.title}
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </li>
                    <li className="nav-item  active">
                      <Link
                        className="nav-link"
                        to={process.env.PUBLIC_URL + "/instructors"}
                      >
                        أعضاء هيئة التدريس{" "}
                      </Link>
                    </li>

                    <li className="nav-item  active">
                      <Link
                        className="nav-link"
                        to={process.env.PUBLIC_URL + "/events"}
                      >
                        الاخبار و الاعلانات
                      </Link>
                    </li>
                    <li className="nav-item  active">
                      <Link
                        className="nav-link"
                        to={process.env.PUBLIC_URL + "/contact"}
                      >
                        اتصل بنا
                      </Link>
                    </li>
                  </ul>
                  <ul className="nav search-cart-bar">
                    <li className="nav-item search-box">
                      <Search />
                    </li>

                    <li className="nav-item side-box">
                      <Sidebar />
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Sticky Menu */}
      <StickyMenu />

      {/* Mobile Menu */}
      <MobileMenu departments={departments} />
    </Styles>
  );
};

export default Header;
