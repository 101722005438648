import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { URL } from "../../../../redux/actions";
import { getSubjects } from "../../../../redux/actions/Subjects.action";

const Plans = ({ departmentId }) => {
  const semester = [
    { num: "1", label: "السنة الاولى" },
    { num: "2", label: " السنة الثانية" },
    { num: "3", label: "السنة الثالثة" },
    { num: "4", label: "السنة الرابعة " },
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSubjects(departmentId));
  }, [departmentId]);
  const { subjects } = useSelector((state) => state.subjects);
  return (
    <>
      {semester.map((s) => (
        <div className="course-element">
          <h5>{s.label}</h5>
          <div className="course-item">
            <div className="course-content show">
              <ul className="list-unstyled">
                {subjects &&
                  subjects.map((subject) => (
                    <>
                      {subject.klass === s.num && (
                        <li>
                          <img
                            src={`${URL}${subject.image}`}
                            alt={subject.subjectname}
                            width="70"
                            className="m-2 rounded"
                          />
                          <span className="lecture-title">
                            {subject.subjectname}
                          </span>

                          <p className="lecture-title m-3 d-inline">
                            عدد وحدات : {subject.unit}
                          </p>
                          <p className="lecture-title m-3 d-inline">
                            رمز المادة : {subject.about}
                          </p>
                        </li>
                      )}
                    </>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Plans;
