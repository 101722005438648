import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/mobileMenu.js";
import { URL } from "../../redux/actions";
function MobileMenu({ departments }) {
  useEffect(() => {
    // Mobile Menu
    const hmBtn = document.getElementById("mb-sidebar-btn");

    if (hmBtn) {
      const mdSidebarOverlay = document.getElementById("mb-sidebar-overlay");
      const mdSidebarBody = document.getElementById("mb-sidebar-body");
      const mdSidebarExit = document.getElementById("close-mb-sidebar");

      hmBtn.addEventListener("click", function (e) {
        e.preventDefault();
        mdSidebarOverlay.classList.toggle("visible");
        mdSidebarBody.classList.toggle("opened");
      });

      mdSidebarOverlay.addEventListener("click", function (e) {
        e.preventDefault();
        mdSidebarOverlay.classList.remove("visible");
        mdSidebarBody.classList.remove("opened");
      });

      mdSidebarExit.addEventListener("click", function (e) {
        e.preventDefault();
        mdSidebarOverlay.classList.remove("visible");
        mdSidebarBody.classList.remove("opened");
      });
    }

    // Menu Accordion -----------------
    const menuButton = document.querySelectorAll(".mb-menu-button");
    menuButton.forEach((button) => {
      button.addEventListener("click", () => {
        button.classList.toggle("active");
        const content = button.nextElementSibling;

        if (button.classList.contains("active")) {
          content.className = "mb-menu-content show";
          content.style.maxHeight = content.scrollHeight + "px";
        } else {
          content.className = "mb-menu-content";
          content.style.maxHeight = "0";
        }
      });
    });
  });

  return (
    <Styles>
      {/* Mobile Menu */}
      <section className="mobile-menu-area" style={{ direction: "rtl" }}>
        <Container>
          <Row>
            <Col md="0" sm="12">
              <div className="mb-topbar d-flex justify-content-between">
                <div className="topbar-item">
                  <p>
                    8009 805 (092)
                    <i className="las la-phone"></i>
                  </p>
                </div>
                <div className="topbar-item">
                  <ul className="list-unstyled list-inline">
                    <li className="list-inline-item">
                      <a target="_blank" href={`${URL}`}>
                        <i className="las la-user"></i>تسجيل دخول
                      </a>{" "}
                    </li>
                    <li className="list-inline-item">/</li>
                    <li className="list-inline-item">
                      <a href={`${URL}signup`} target="_blank">
                        <i className="las la-user-edit"></i>سجل الان
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mb-logo-area d-flex justify-content-between">
                <div className="mb-logo-box d-flex">
                  <div className="hm-button">
                    <a href={process.env.PUBLIC_URL + "/"} id="mb-sidebar-btn">
                      <i className="las la-bars"></i>
                    </a>
                  </div>
                  <div className="mb-logo">
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      <img src="assets/images/univ.png" alt="" width="100" />
                    </Link>
                  </div>
                </div>
                <div className="mb-search-box">
                  <form action="#">
                    <input type="text" name="search" placeholder="ابحث هنا" />
                    <button type="submit">
                      <i className="las la-search"></i>
                    </button>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Mobile Menu Sidebar */}
      <section className="mb-sidebar" id="mb-sidebar-body">
        <div className="mb-sidebar-heading d-flex justify-content-between">
          <div>
            <h5>القائمة</h5>
          </div>
          <div>
            <a href={process.env.PUBLIC_URL + "/"} id="close-mb-sidebar">
              <i className="las la-times"></i>
            </a>
          </div>
        </div>
        <div className="mb-sidebar-menu">
          <div className="mb-menu-item">
            <button className="mb-menu-button active">
              <p>
                صفحات الموقع <i className="las la-plus"></i>
              </p>
            </button>
            <div className="mb-menu-content show">
              <ul className="list-unstyled">
                <li>
                  <Link to="/">الرئيسية</Link>
                </li>
                <li>
                  <Link to="/about">عن الجامعة</Link>
                </li>
                <li>
                  <Link to="/instructors">أعضاء هيئة التدريس </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/events"}>
                    الاخبار و الاعلانات
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/contact"}>اتصل بنا</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="mb-menu-item">
            <button className="mb-menu-button active">
              <p>
                الاقسام <i className="las la-plus"></i>
              </p>
            </button>
            <div className="mb-menu-content show">
              <ul className="list-unstyled">
                {departments &&
                  departments.map((department) => (
                    <li key={department._id}>
                      <Link to={department.Url}>{department.title}</Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="mb-sidebar-overlay" id="mb-sidebar-overlay"></div>
    </Styles>
  );
}

export default MobileMenu;
